<template>
  <div class="tool-view">
    <div class="open-box">
      是否显示：
      <a-switch
        checked-children="开"
        un-checked-children="关"
        :disabled="isLoading"
        :checked="swicthStatus"
        @change="changeSwitch"
      />
    </div>

    <div class="flex-auto"></div>
    <a-button type="primary" @click="handleAdd()"><plus-outlined />添加榜单</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :loading="isLoading"
    :pagination="false"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 跳转 -->
      <template v-if="column.key === 'type'">
        {{ record.type === 3 ? '聚合页' : (record.type === 1 ? '剧情类型' : '剧情标签') }}
      </template>
      <!-- 剧名 -->
      <template v-if="column.key === 'content'">
        <div v-for="play in record.content"  v-bind:key="play" class="play-name">{{ play.name }}</div>
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
      <!-- 编辑 -->
        <a-button
          type="link"
          @click="handleAdd(record)"
        >
          编辑
        </a-button>
        <!-- 删除 -->
        <a-popconfirm
          title="确定要删除吗？"
          @confirm="handleDelete(record)"
        >
          <a-button type="link">删除</a-button>
        </a-popconfirm>
        <!-- 上移：第一组禁用 -->
        <a-button
          type="link"
          @click="handleSort(record, 'up')"
          :disabled="index === 0"
        >
          上移
        </a-button>
        <!-- 下移：最后一组禁用 -->
        <a-button
          type="link"
          @click="handleSort(record, 'down')"
          :disabled="index === dataSource.length - 1"
        >
          下移
        </a-button>
      </template>
    </template>
  </a-table>
  <!-- 新增、编辑抽屉 -->
  <Add ref="RefAdd" @success="getFrontSourceList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { Platforms, FrontSource} from '@/utils/constantList'
import { message } from 'ant-design-vue'
import Add from './Add'
import { frontSourceSettingDetail, frontSourceSetting, frontSourceList, frontSourceSort, frontSourceDelete } from '@/api/operate'

// 新增、编辑组件实例
let RefAdd = ref(null)
// props
const props = defineProps({
  platform: Number
})
// source类型
const source = FrontSource.nav
// 公共设置详情
let settingDetail = ref({})
// 是否显示
let swicthStatus = ref(false)
// 调用项选中状态
let checkedOption = ref(undefined)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '顺序',
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '内容类型',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: '内容类型',
    dataIndex: 'type_id_name',
    key: 'type_id_name'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount(() => {
  // 获取公共配置详情
  getFrontSourceSettingDetail()
  // 获取配置列表
  getFrontSourceList()
})

// 获取公共配置详情
function getFrontSourceSettingDetail () {
  isLoading.value = true
  frontSourceSettingDetail({
    source,
    platform_id: props.platform
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      if (data) {
        // 保存顶部公共设置
        settingDetail.value = data
        // 调用平台
        checkedOption.value = data.use_platform_id
        // 开关
        swicthStatus.value = data.status === 1 ? true : false
      } else {
        // 首次设置，调用平台 初始化为 当前platform
        checkedOption.value = props.platform
      }
    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取配置列表
function getFrontSourceList () {
  isLoading.value = true
  frontSourceList({
    source,
    platform_id: props.platform
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 设置公共配置
function setFrontSourceSetting (obj = {}) {
  isLoading.value = true
  const params = { 
    source,
    platform_id: props.platform,
    use_platform_id: checkedOption.value,
    status: swicthStatus.value,
    // 覆盖项
    ...obj
  }
  frontSourceSetting(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新详情
      getFrontSourceSettingDetail()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 修改调用项勾选状态（单选！选中传id，取消则传自身平台 platform-id）
function changeCheckedOption (id, e) {
  setFrontSourceSetting({
    use_platform_id: e.target.checked ? id : props.platform
  })
}

// 切换开关
function changeSwitch (boolean) {
  setFrontSourceSetting({
    status: boolean ? 1 : 0
  })
}

// 新增、编辑榜单
function handleAdd (record) {
  RefAdd.value.showDrawer({
    // 当前平台
    platform: props.platform,
    // 单条记录id
    id: record ? record.id : undefined,
    //类型
    type: record ? record.type : undefined
  })
}

// 删除榜单 
function handleDelete (record) {
  isLoading.value = true
  const params = {
    source,
    id: record.id
  }
  frontSourceDelete(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('删除成功')
      // 更新列表
      getFrontSourceList()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 排序榜单
function handleSort (record, type) {
  isLoading.value = true
  const params = {
    source,
    id: record.id,
    type
  }
  frontSourceSort(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getFrontSourceList()
    } else {
      message.error(res.message || msg)
    }
  })
}

</script>

<style lang="less" scoped>
.tool-view {
  display: flex;
  align-items: center;
  padding: 10px 10px 20px;
  .open-box {
    margin-right: 50px;
    display: flex;
    align-items: center;
  }
  .option-box {
    display: flex;
    align-items: center;
  }
  .flex-auto {
    flex: 1;
  }
}
.table-view {
  :deep(.ant-table-cell) {
    vertical-align: top;
  }
  .play-name {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
}
.ant-btn-link {
  padding: 0;
  margin-left: 8px;
}
</style>