<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑导航' : '新增导航'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 模块名称 -->
      <a-form-item
        label="模块名称"
        name="name"
        :rules="[{ required: true, message: '请输入模块名称' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
          :maxlength="10"
        />
      </a-form-item>
      <!-- 顺序 -->
      <a-form-item
        label="顺序"
        name="sort"
        :rules="[{ required: true, validator: validator }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.sort"
        />
      </a-form-item>
      <!-- 短剧 -->
      <a-form-item
        label="内容类型"
        :rules="[{ required: true }]"
      >
        <a-select
          style="width: 350px"
          show-search
          placeholder="请选择"
          v-model:value="formState.type"
          :filter-option="filterOption"
          @change="changeType(index, $event)"
        >
          <a-select-option
            v-for="types in typeMap"
            :key="`${types.id}-${types.name}`"
            :value="types.id"
          >
            {{ types.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="页面内容"
        :rules="[{ required: true }]"
      >
        <a-select
          style="width: 350px"
          show-search
          placeholder="请选择"
          v-model:value="formState.type_id"
          :filter-option="filterOption"
          @change="changeTypeId(index, $event)"
        >
          <a-select-option
            v-for="types in typeIdNameList"
            :key="`${types.id}-${types.name}`"
            :value="types.id"
          >
            {{ types.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import Loading from '@/components/Loading'
import { message } from 'ant-design-vue'
import Pub from '@/utils/public'
import { FrontSource} from '@/utils/constantList'
import { frontSourceDetail, typeIdList, navSave } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// source类型
const source = FrontSource.nav
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// platform
let platform = ref(undefined)
// id
let id = ref(undefined)
// 短剧列表
let dramaList = ref([])
// 页面内容列表
let typeIdNameList = ref([])
// 剧集信息
let formState = reactive({
  // 文案
  name: undefined,
  // 顺序
  sort: undefined,
  // 内容类型
  type: undefined,
  // 内容类型名称
  type_name: undefined,
  // 页面内容
  type_id: undefined,
  // 页面内容名称
  type_id_name: undefined
})

//内容类型
const typeMap = [
  {
    id: 1,
    name: '剧情类型'
  },
  {
    id: 2,
    name: '剧情标签'
  },
  {
    id: 3,
    name: '聚合页'
  }
]

// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // 当前平台
  platform.value = params.platform
  // id
  id.value = params.id
  //获得数据详情
  if (id.value) {
    // 获取详情
    getFrontSourceDetail()
    getTypeIdList(params.type) 

  } 
}


function getTypeIdList (type) {
  isLoading.value = true    
  typeIdList({ type: type }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      typeIdNameList.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}
// 获取配置详情
function getFrontSourceDetail () {
  isLoading.value = true
  const params = {
    source,
    id: id.value
  }
  frontSourceDetail(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 名称
      formState.name = data.name
      // 排序
      formState.sort = data.sort
      // 内容类型
      formState.type = data.type
      // 内容类型名称
      formState.type_name = data.type_name
      // 内容类型
      formState.type_id = data.type_id
      // 内容类型名称
      formState.type_id_name = data.type_id_name
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 表单重置
    RefForm.value.resetFields()
    visible.value = false
    formState.type = undefined
    formState.type_id = undefined
  }
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 修改短剧选中：把选中剧的 name 塞进 formState.plays 中
function changeType (index, e) {
  formState.type_id = undefined
  //   formState.plays[index].name = dramaList.value.filter(item => item.id === e)[0].name
  formState.type_name = typeMap.filter(item => item.id === e)[0].name
  getTypeIdList(e) 
}

function changeTypeId (index, e) {
  formState.type_id_name = typeIdNameList.value.filter(item => item.id === e)[0].name
  console.log(formState.type_id_name)
}

// 表单校验
function validator (rule, value) {
  // 封面
  if (rule.field === 'sort') {
    if (!value) {
      return Promise.reject('请输入顺序')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const params = {
      platform_id: platform.value,
      id: id.value,
      name: formState.name,
      sort: formState.sort,
      type: formState.type,
      type_id: formState.type_id,
      type_name: formState.type_name,
      type_id_name: formState.type_id_name,
    }
    navSave(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.play-item {
  margin-bottom: 16px;
  .ant-btn-link {
    padding: 0;
    margin-left: 8px;
  } 
}

</style>